<template>
    <v-container>
        <v-row justify="center">
            <v-col class="text-center" cols="12">
                Выберите схемы:
            </v-col>
            <v-col cols="12">
                <v-row align="center" justify="center" no-gutters>
                    <v-text-field
                        v-model="filter"
                        class="shrink"
                        dense
                        hide-details
                        placeholder="Поиск по схемам"
                        solo
                        style="width: 550px;"
                    />
                    <v-divider class="mx-4" vertical />
                    <v-checkbox
                        :indeterminate="
                            selectedSchemes.length > 0 &&
                                selectedSchemes.length !==
                                    filteredSchemes.length
                        "
                        :label="selectAllLabel"
                        :value="selectAllValue"
                        class="my-0"
                        hide-details
                        @change="selectAll"
                    />
                </v-row>
            </v-col>
            <v-col cols="12">
                <v-row justify="center" no-gutters>
                    <v-card max-width="800px">
                        <v-list
                            dense
                            expand
                            flat
                            max-height="400px"
                            style="overflow: auto;"
                            subheader
                        >
                            <v-list-item-group
                                v-model="selectedSchemes"
                                active-class=""
                                multiple
                            >
                                <v-list-item
                                    v-for="sh in filteredSchemes"
                                    :key="sh.id"
                                    :value="sh"
                                >
                                    <template v-slot:default="{ active }">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ sh.name }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle
                                                class="text-wrap"
                                            >
                                                {{ sh.description }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-checkbox
                                                :input-value="active"
                                            ></v-checkbox>
                                        </v-list-item-action>
                                    </template>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-card>
                </v-row>
            </v-col>
            <v-col class="text-center" cols="12">
                <v-btn @click="proceed" color="primary">
                    Подтвердить выбор
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "PlainSchemes",
    mounted() {
        this.$store.dispatch("loading", true);
        this.axios.get(`/api/schemes`).then(resp => {
            this.schemes = resp.data;
            this.selectedSchemes = [...this.schemes];
            this.$store.dispatch("loading", false);
        });
    },
    data: () => ({
        schemes: [],
        selectedSchemes: [],
        filter: ""
    }),
    computed: {
        filteredSchemes() {
            return this.schemes.filter(sh => {
                return (
                    sh.name.toLowerCase().includes(this.filter.toLowerCase()) ||
                    sh.description
                        .toLowerCase()
                        .includes(this.filter.toLowerCase())
                );
            });
        },
        selectAllValue() {
            return this.schemes.length > 0
                ? this.selectedSchemes.length === this.filteredSchemes.length
                : 1;
        },
        selectAllLabel() {
            return this.selectedSchemes.length === this.filteredSchemes.length
                ? "Снять выделение"
                : this.selectedSchemes.length > 0
                ? "Выбрать оставшиеся"
                : "Выбрать все";
        }
    },
    methods: {
        selectAll() {
            if (this.selectedSchemes.length !== this.filteredSchemes.length) {
                this.selectedSchemes = [...this.filteredSchemes];
            } else {
                this.selectedSchemes = [];
            }
        },
        proceed() {
            this.$store.commit(
                "setSchemeList",
                this.selectedSchemes.map(sh => sh.id)
            );
            this.$router.push(`/test-options`);
        }
    }
};
</script>

<style scoped></style>
